import React, { Fragment, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import Compte from '../../../assets/images/header_comptes.jpg'
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "COMPTEEPARGNE"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const COMPTEEPARGNE = gql`
    query {
        allCategorieCompteEpargnes (
            sortBy: Ordre_ASC
        ) {
        id
        Nom
        CompteEpargne (
            sortBy: Ordre_ASC
        ) {
            id
            Titre
            Contenu
        }
        }
    }
`;

function CompteEpargneArticle () {
    const [currentCollapse, setCollapse] = useState([]);
    const [visible, setVisible] = useState(false)
    const { loading: loadingCompte, error: errorCompte, data: dataCompte } = useQuery(COMPTEEPARGNE);
    const { loading, error, data } = useQuery(ACCROCHES);
    const openPopup = () => {
        setVisible(true);
    } 

    const listStyle = {
        marginLeft: '49px',
        fontSize: '1.3333333333rem',
        fontWeight: '400',
        lineHeight: 1.6,
        listStyle: 'disc'
    }

    const foireAuxQuestions = [
        { 
            id: 1,
            Titre: '',
            SousTitre: 'Quelles sont les conditions pour retirer de l\'argent de mon compte d\'épargne ? ',
            Description: 'Les retraits peuvent être effectués à tout moment, mais certaines conditions s\'appliquent en fonction du type de compte. Les retraits importants peuvent nécessiter un préavis, et des limites de montant s’appliquent selon le compte. Il peut y avoir des pénalités en cas de non-respect du préavis de retrait '
        },
        { 
            id: 2,
            Titre: '',
            SousTitre: 'Y a-t-il des avantages fiscaux liés aux comptes d\'épargne ? ',
            Description: 'Oui, le compte de prévoyance 3e pilier 3a peut offrir des avantages fiscaux. '
        },
        { 
            id: 3,
            Titre: '',
            SousTitre: 'Quelles sont les options de placement disponibles pour augmenter mon épargne ? ',
            Description: 'Les comptes à taux préférentiel proposent des taux d’intérêt plus élevés pour des placements à plus long terme. '
        }
    ]
    

    const handleCancel = e => {
        console.log(e);
        setVisible(false);
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if (loadingCompte) return <p>Loading...</p>;
    if (errorCompte) return <p>Error :(</p>;

        const addCollapse = (id) => {
            if(currentCollapse.includes(id)) {
                setCollapse(currentCollapse.filter((e)=>(e !== id)));
            } else {
                setCollapse([...currentCollapse, id ]);
            }
        }

        return (
            <Fragment>
                <div className="slide-home">
                    <div className="content-slides">
                        <div className="images">
                            <img src={Compte} alt="Ceanet" />
                        </div>
                    </div>
                </div>

                {
                    data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                        <div key={accroche.id} className="col-md-12 compte-epargne-info">
                            <div className="container" style={{marginLeft: 50}}>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="info-contact info-one-column">
                                            <h1>{accroche.Titre}</h1>
                                            <p>{accroche.SousTitre}</p>
                                        </div>
                                    </div>
                                </div>
                                <Link to={"/Contact"} className="compte-epargne-btn">
                                    Ouvrir un compte
                                </Link>
                            </div>
                            <div className='blocs-container' style={{marginTop: 60}}>
                                <p className='page_lead--lead--text'>{accroche.Description}</p>
                                {/* <Link to={"/Contact"} className="compte-epargne-btn" style={buttonStyle} >
                                    Ouvrir un compte
                                </Link> */}
                            </div>
                            
                        </div>
                    )): ''
                }
                <div id="main-wrapper" className="col-md-12">
                    <div className="blocs-container">
                        <h1 style={{paddingBottom: 75}}>Découvrez les avantages du Compte Épargne</h1>
                        <p className='page_lead--lead--text'>Le Compte Épargné est un produit bancaire qui vous permet de déposer de l'argent de manière sécurisée tout en générant des intérêts sur la somme épargnée. Il permet de constituer une réserve d’argent pour des projets futurs ou pour faire face à des imprévus financiers.</p>
                        <h2>Votre capital est accessible en tout temps.</h2>
                        <p className='page_lead--lead--text'>Vous déterminez le montant et la fréquence de vos versements et les retraits peuvent être effectués à tout moment.</p>
                        <h2>Taux d’intérêt attractif.</h2>
                        <p className='page_lead--lead--text'>En possédant un Compte Épargne en complément à votre Compte Privé, vous pouvez générer des intérêts compétitifs sur l’argent que vous désirez épargner.</p>
                        <h2>Une banque fiable et accessible.</h2>
                        <p className='page_lead--lead--text'>Bénéficiez d’un service de proximité et d’un accompagnement personnalisé. À chacun de vos projets, votre conseiller vous propose une solution d’épargne.</p>
                        <h2>Sécurité des fonds.</h2>
                        <p className='page_lead--lead--text'>La Caisse d’Épargne d’Aubonne garantit la sécurité des dépôts par une gestion prudente. Vos fonds sont protégés par la garantie des dépôts esisuisse et assurés jusqu'à un plafond de CHF 100’000 par client.</p>
                        <h2 style={{paddingBottom: 10}}>Conditions</h2>
                        <ul style={listStyle}>
                            <li style={{listStyle: 'disc'}}>Identité : Vous devez fournir une pièce d'identité valide (carte d'identité ou passeport) et être âgé de 18 ans ou plus. </li>
                            <li style={{listStyle: 'disc'}}>Domicile : Vous devez habiter en Suisse. </li>
                            <li style={{listStyle: 'disc'}}>Dépôt minimum : Selon le type de compte choisi, un montant minimum peut être requis à l'ouverture. </li>
                        </ul>
                    </div>
                    <div className="compte-epargne-container">
                        <div>
                            <h2 style={{textAlign: 'center', padding: '20px 0'}}>Ouvrir un Compte Épargne, <br></br> c’est simple et sécurisé ! </h2>
                            <div className='bloc-de-transition'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={50} height={50} fill='#1B327E'>
                                    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
                                    </svg>
                                    <h3>1. Formulaire</h3>
                                    <p>Choisissez le type de compte épargne qui correspond à vos besoins <br></br>et remplissez le formulaire d’ouverture de compte en ligne ou rendez-vous en agence.</p>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={50} height={50} fill='gray'><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
                                </svg>

                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={50} height={50} fill='#1B327E'><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-19.3c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2l-64 0c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2L80 448c-8.8 0-16-7.2-16-16s7.2-16 16-16l8.2 0c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8 8.9 0c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6l0-43.6-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z"/>
                                    </svg>
                                    <h3>2. Signature</h3>
                                    <p>Une fois les documents validés, le compte est activé et les fonds peuvent être déposés.</p>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={50} height={50} fill='grey'><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
                                </svg>

                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={50} height={50} fill='#1B327E'><path d="M272.2 64.6l-51.1 51.1c-15.3 4.2-29.5 11.9-41.5 22.5L153 161.9C142.8 171 129.5 176 115.8 176L96 176l0 128c20.4 .6 39.8 8.9 54.3 23.4l35.6 35.6 7 7c0 0 0 0 0 0L219.9 397c6.2 6.2 16.4 6.2 22.6 0c1.7-1.7 3-3.7 3.7-5.8c2.8-7.7 9.3-13.5 17.3-15.3s16.4 .6 22.2 6.5L296.5 393c11.6 11.6 30.4 11.6 41.9 0c5.4-5.4 8.3-12.3 8.6-19.4c.4-8.8 5.6-16.6 13.6-20.4s17.3-3 24.4 2.1c9.4 6.7 22.5 5.8 30.9-2.6c9.4-9.4 9.4-24.6 0-33.9L340.1 243l-35.8 33c-27.3 25.2-69.2 25.6-97 .9c-31.7-28.2-32.4-77.4-1.6-106.5l70.1-66.2C303.2 78.4 339.4 64 377.1 64c36.1 0 71 13.3 97.9 37.2L505.1 128l38.9 0 40 0 40 0c8.8 0 16 7.2 16 16l0 208c0 17.7-14.3 32-32 32l-32 0c-11.8 0-22.2-6.4-27.7-16l-84.9 0c-3.4 6.7-7.9 13.1-13.5 18.7c-17.1 17.1-40.8 23.8-63 20.1c-3.6 7.3-8.5 14.1-14.6 20.2c-27.3 27.3-70 30-100.4 8.1c-25.1 20.8-62.5 19.5-86-4.1L159 404l-7-7-35.6-35.6c-5.5-5.5-12.7-8.7-20.4-9.3C96 369.7 81.6 384 64 384l-32 0c-17.7 0-32-14.3-32-32L0 144c0-8.8 7.2-16 16-16l40 0 40 0 19.8 0c2 0 3.9-.7 5.3-2l26.5-23.6C175.5 77.7 211.4 64 248.7 64L259 64c4.4 0 8.9 .2 13.2 .6zM544 320l0-144-48 0c-5.9 0-11.6-2.2-15.9-6.1l-36.9-32.8c-18.2-16.2-41.7-25.1-66.1-25.1c-25.4 0-49.8 9.7-68.3 27.1l-70.1 66.2c-10.3 9.8-10.1 26.3 .5 35.7c9.3 8.3 23.4 8.1 32.5-.3l71.9-66.4c9.7-9 24.9-8.4 33.9 1.4s8.4 24.9-1.4 33.9l-.8 .8 74.4 74.4c10 10 16.5 22.3 19.4 35.1l74.8 0zM64 336a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm528 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/>
                                    </svg>
                                    <h3>3. À vos côtés</h3>
                                    <p>Rencontrez votre conseiller pour échanger sur vos futurs projets et développer votre épargne.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="section-offre">
                    <div className="container" style={{maxWidth: '100%', margin: '0 50px'}}>
                        <div className="row">
                            {/*<p>{dataCompte}</p>*/}
                            <div className="slide-mobile">
                            {  
                                dataCompte && dataCompte.allCategorieCompteEpargnes ? dataCompte.allCategorieCompteEpargnes.map((categorie) => (
                                    <article key={categorie.id} style={{width: '20%'}}>
                                    {
                                        categorie && categorie.CompteEpargne ? categorie.CompteEpargne.map((compteEpargne) => (
                                        //<img src={'uploads/' + (offre.Image && offre.Image.filename ? offre.Image.filename : '')} alt={offre.Image && offre.Image.filename ? offre.Image.filename : ''} />
                                        <div className="content">
                                            <h3>{compteEpargne.Titre}</h3>
                                            <div className="description-height" dangerouslySetInnerHTML={{ __html: compteEpargne.Contenu }}  />
                                            <Link className="link-decouvrir" to="">En savoir plus</Link>
                                        </div>
                                        )) : ''
                                    }
                                    </article>
                                )) : ''
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div id="main-wrapper" className="col-md-12" style={{padding: '50px 0 80px 0'}}>
                    <div className="compte-epargne-container">
                        
                            <h2 style={{textAlign: 'center', padding: '40px 0'}}>Foire aux questions</h2>
                            <div className="collapsed-row">
                                {
                                    foireAuxQuestions && foireAuxQuestions ? foireAuxQuestions.map((question) => (
                                        <div key={question.id}>
                                            <h3 style={{overflow:'hidden'}} onClick={() =>
                                                addCollapse(question.id)
                                            }
                                                className={
                                                    currentCollapse.includes(question.id)
                                                        ? "collapsible active"
                                                        : "collapsible"
                                                }
                                            >
                                                {/* <span style={{float:"left", width:"30% !important"}}>{compte.Titre}</span> */}<div style={{color:"#848484 !important",float:"left",marginBottom:"10px",width:"70% !important"}}> {question.SousTitre}</div> 
                                            </h3>
                                            <div className="content-collapse" style={{
                                                display:
                                                    currentCollapse.includes(question.id)
                                                        ? "block"
                                                        : "none",
                                            }}>
                                                {/*<h4> {compte.Titre}</h4>*/}
                                                <p>
                                                    {question.Description}
                                                </p>
                                                {/* <div className="taux">
                                                    <span>Taux</span>
                                                    <span style={{lineHeight:"32px",maxWidth:"200px"}} className="pourcentage-taux" dangerouslySetInnerHTML={{ __html: compte.Taux}}></span>
                                                </div>
                                                <div className="conditions">
                                                    <div className="titre">Conditions</div>
                                                    <div style={{float:'left'}} dangerouslySetInnerHTML={{__html: compte.Contenu}}></div>
                                                </div> */}
                                                <Link to={"/Contact"} className="btn-link">
                                                    Ouvrir un compte
                                                </Link>
                                            </div>

                                        </div>
                                    )) : ''
                                }
                            </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }

export default CompteEpargneArticle
