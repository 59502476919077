import React from 'react';
import './App.css';
import Accueil from './components/pages/Accueil'
import LaBanque from './components/pages/LaBanque/Labanque'
import Comptes from './components/pages/Comptes/Comptes'
import CompteEpargne from './components/pages/CompteEpargne/CompteEpargne';
import Credits from './components/pages/Credits/Credits'
import Services from './components/pages/NosServices/Services'
import VosProjets from './components/pages/VosProjets/VosProjets'
import Actualites from './components/pages/Actualites/Actualites'
import AideFaq from './components/pages/AideFaq/AideFaq'
import Contact from './components/pages/Contact/Contact'
import DemandeFinish from './components/pages/Contact/DemandeFinish';
import ChoixSubscription from './components/pages/Contact/ChoixSubscription'
import Offres from './components/pages/Offres/Offre'
import Conditions from './components/pages/Conditions/Conditions'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import Information from './components/pages/Informations/Information';
import ScrollToTop from './components/ScrollToTop';
/* import { useCookies } from 'react-cookie'; */
/* import Cookies from './components/Cookies/cookies';
import NoticeCookies from './components/Cookies/NoticeCookies'; */
// import Confidentialite from './components/Cookies/Confidentialite';
// import ReactGA from 'react-ga';

// const TRACKING_ID = "G-FECKQNGWX3"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID, {
//   siteSpeedSampleRate: 100
// });

var myHeaders = new Headers();
myHeaders.append("Access-Control-Allow-Origin", "*");

const client = new ApolloClient({
  uri: '/admin/api',
  cache: new InMemoryCache()
});

function App() {
/*   const [cookies, setCookie] = useCookies(['choixCookies']);
  const [cookieGoogle, setCookieGoogle] = useCookies(['cookieGoogle']);
  const [cookieFBpixel, setCookieFBpixel] = useCookies(['cookieFBpixel']); */

/*   function onChange(newName) {
    setCookie('choixCookies', newName);
  }  */
  return ( 
    <ApolloProvider client={client}>
      {/* <Cookies 
        choixCookies={cookies.choixCookies} 
        cookies={cookies} 
        setCookie={setCookie} 
        cookieGoogle={cookieGoogle?.cookieGoogle}
        cookieFBpixel={cookieFBpixel?.cookieFBpixel}
        setCookieGoogle={setCookieGoogle}
        setCookieFBpixel={setCookieFBpixel}
        onChange={onChange} 
      /> */}
      <Router >
      <ScrollToTop/>
        <Switch>
          <Redirect from="/rapports" to="AideFaq" />
          <Route exact path="/" component={Accueil} />
          <Route path="/LaBanque" component={LaBanque} />
          <Route path="/Comptes" component={Comptes} />
          <Route path="/CompteEpargne" component={CompteEpargne} />
          <Route path="/Credits" component={Credits} />
          <Route path="/Services" component={Services} />
          <Route path="/VosProjets" component={VosProjets} />
          <Route path="/Actualites" component={Actualites}/>
          <Route path="/AideFaq" component={AideFaq} />
          <Route path="/Contact" component={Contact} />
          <Route path="/AideFaq" component={AideFaq} />
          <Route path="/Conditions" component={Conditions} />
          <Route path="/Offres/:id" component={Offres} />
          <Route path="/client-cree" component={DemandeFinish} />
          <Route path="/start-oid" component={ChoixSubscription} />
          <Route path="/rdv-ouverture-compte/:token" component={DemandeFinish} />
          <Route path="/Informations" component={Information}/>
          {/* <Route path="/Notice-Cookies" component={NoticeCookies}/> */}
          {/* <Route path="/Confidentialite" component={Confidentialite}/> */}
        </Switch>
      </Router>
    </ApolloProvider>

  );
}

export default (App);
