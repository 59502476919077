import React, { Fragment, Component } from 'react'
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import CompteEpargneArticle from '../CompteEpargne/CompteEpargneArticle'
import Section from '../../Article/Section'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import { Helmet } from 'react-helmet'



class CompteEpargne extends Component {

    componentDidMount() {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }

    render() {
        return (
            <Fragment>
              <Helmet>
                <title>Caisse d’épargne d’Aubonne - Compte Épargne </title>
                <meta name="description" content="A chaque étape de votre vie, nous avons un compte épargne qui s’adapte à vos besoins.  Préparez votre avenir en toute sérénité grâce à nos taux compétitifs, une gestion prudente, et la sécurité d'une banque humaine et proche de sa communauté." />
              </Helmet>
                <Header />
                <SideBtnLink />
                <CompteEpargneArticle />
                {/* <Section /> */}
                <Footer />
                <Copyright />
            </Fragment>
        )
    }
}

export default CompteEpargne
