import React, { Fragment, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import AsideContentInfo from '../../Aside/AsideContentInfo'
import Compte from '../../../assets/images/header_comptes.jpg'
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import TableauComparatif from './TableauComparatif'
const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "COMPTE"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const COMPTES = gql`
    query {
        allCategorieComptes (
            sortBy: Ordre_ASC
        ) {
        id
        Nom
        Comptes  (
            sortBy: Ordre_ASC
        ) {
            id
            Titre
            Taux
            SousTitre
            Description
            Contenu
        }
        }
    }
`;

function CompteArticle () {
    const [currentCollapse, setCollapse] = useState([]);
    const [visible, setVisible] = useState(false)
    const { loading: loadingCompte, error: errorCompte, data: dataCompte } = useQuery(COMPTES);
    const { loading, error, data } = useQuery(ACCROCHES);
    const openPopup = () => {
        setVisible(true);
    }

    const handleCancel = e => {
        console.log(e);
        setVisible(false);
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if (loadingCompte) return <p>Loading...</p>;
    if (errorCompte) return <p>Error :(</p>;

        const addCollapse = (id) => {
            if(currentCollapse.includes(id)) {
                setCollapse(currentCollapse.filter((e)=>(e !== id)));
            } else {
                setCollapse([...currentCollapse, id ]);
            }
        }

        return (
            <Fragment>
                <div className="slide-home">
                    <div className="content-slides">
                        <div className="images">
                            <img src={Compte} alt="Ceanet" />
                        </div>
                    </div>
                </div>
                <div className="tab-comparatif" >
                    <Modal
                        // title="Basic Modal"
                        visible={visible}
                        // onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        width={1000}
                        responsive

                    >
                        <TableauComparatif />
                    </Modal>
                </div>


                {
                    data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                        <div key={accroche.id} className="col-md-12 info-home">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="info-contact info-one-column">
                                            <h1>{accroche.Titre}</h1>
                                            <p>{accroche.Description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )): ''
                }
                <div id="main-wrapper" className="col-md-12">
                    <div className="container">
                        <div className="side-left">
                            {
                                dataCompte && dataCompte.allCategorieComptes ? dataCompte.allCategorieComptes.map((categorie) => (
                                    <div key={categorie.id} className="collapsed-row">
                                        <h2>{categorie.Nom}</h2>
                                        {
                                            categorie && categorie.Comptes ? categorie.Comptes.map((compte) => (
                                                <div key={compte.id}>
                                                    <h3 style={{overflow:'hidden'}} onClick={() =>
                                                        addCollapse(compte.id)
                                                    }
                                                        className={
                                                            currentCollapse.includes(compte.id)
                                                                ? "collapsible active"
                                                                : "collapsible"
                                                        }
                                                    >
                                                        <span style={{float:"left", width:"30% !important"}}>{compte.Titre}</span> <div style={{color:"#848484 !important",float:"left",marginBottom:"10px",width:"70% !important"}}> {compte.SousTitre}</div>
                                                    </h3>
                                                    <div className="content-collapse" style={{
                                                        display:
                                                            currentCollapse.includes(compte.id)
                                                                ? "block"
                                                                : "none",
                                                    }}>
                                                        {/*<h4> {compte.Titre}</h4>*/ }
                                                        <p dangerouslySetInnerHTML={{__html: compte.Description}}>
                                                        </p>
                                                        <div className="taux">
                                                            <span>Taux</span>
                                                            <span style={{lineHeight:"32px",maxWidth:"200px"}} className="pourcentage-taux" dangerouslySetInnerHTML={{ __html: compte.Taux}}></span>
                                                        </div>
                                                        <div className="conditions">
                                                            <div className="titre">Conditions</div>
                                                            <div style={{float:'left'}} dangerouslySetInnerHTML={{__html: compte.Contenu}}></div>
                                                        </div>
                                                        <Link to={"/Contact"} className="btn-link">
                                                            Ouvrir un compte
                                                        </Link>
                                                    </div>

                                                </div>
                                            )) : ''
                                        }
                                    </div>

                                )) : ''
                            }
                        </div>
                        <AsideContentInfo openPopup={openPopup}/>
                    </div>
                </div>
            </Fragment>
        );
    }

export default CompteArticle
